/* Container styling */
.calendar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

/* Custom calendar styling */
.custom-calendar {
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

/* Tile box styling to look like the Microsoft calendar */
.tile-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    padding: 5px;
    height: 100px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

/* Workdays style - dates in black */
.workday .tile-header {
    font-weight: bold;
    font-size: 1.2rem;
    color: black;
}

/* Weekends style - dates in default color or red */
.weekend .tile-header {
    font-weight: bold;
    font-size: 1.2rem;
    color: #f44336;
    /* Weekend dates in red */
}

.tile-body {
    text-align: center;
    font-size: 0.85rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.trade-info {
    font-size: 1rem;
    font-weight: bold;
    color: #4caf50;
}

.profit-info {
    font-size: 0.9rem;
    color: #f44336;
}

/* Hover effect to highlight the tile */
.react-calendar__tile:hover .tile-box {
    background-color: #f9f9f9;
    cursor: pointer;
    transform: scale(1.05);
    transition: all 0.3s ease;
}

/* Highlight selected day */
.react-calendar__tile--active .tile-box {
    background-color: #87ceeb;
    color: white;
}

/* Popup modal for details */
.details-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 8px;
}

.details-popup h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
}

.details-popup p {
    margin: 5px 0;
}

.details-popup button {
    margin-top: 15px;
    padding: 8px 12px;
    background-color: #87ceeb;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.details-popup button:hover {
    background-color: #4682b4;
}

/* Style the "Back to Now" button */
.back-to-now-btn {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
}

.back-to-now-btn:hover {
    background-color: #333;
}

/* Styling the calendar navigation buttons */
.react-calendar__navigation button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.react-calendar__navigation button:hover {
    background-color: #333;
}

/* Disable next and previous year buttons */
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    display: none;
}


.monthly-profit {
    display: flex;
    justify-content: flex-start;
    /* Align children to the left */
    padding: 2px;
}

.tile-box-green{
    background-color:rgba(0, 128, 0, 0.514)
}

.tile-box-red{
    background-color:rgba(255, 0, 0, 0.397)
}


.weekly-profit {
    margin-top: 20px;
  }
  
  .weekly-profit-item {
    margin-bottom: 10px;
  }
  
  .profit-negative {
    color: red;
  }
  
  .profit-positive {
    color: green;
  }
  
