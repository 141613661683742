/* General App Styling */
body {
  font-family: 'Arial', sans-serif;
  background-color: #e0e0e0;
  color: #333;
  margin: 0;
  padding: 0;
}

.App {
  max-width: 1800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

/* Buttons */
button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

button:hover {
  background-color: #0056b3;
}

/* Filters Container */
.filters-container {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.filters-list {
  margin-bottom: 15px;
}

.filter-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.filter-item select, .filter-item input {
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.filter-item button {
  background-color: #dc3545;
  padding: 5px 10px;
}

.filter-item button:hover {
  background-color: #c82333;
}

.matching-count p {
  font-weight: bold;
  margin: 0;
}

/* Dropzone */
.dropzone {
  border: 2px dashed #ccc;
  padding: 40px;
  text-align: center;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.dropzone.dragging {
  border-color: #007bff;
}

.dropzone p {
  margin: 0;
  font-size: 1.1em;
}

.dropzone label {
  color: #007bff;
  font-weight: bold;
}

/* Table */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

th, td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
  font-size: 1em;
}

th {
  background-color: #007bff;
  color: white;
  cursor: pointer;
  position: relative;
}

th:hover {
  background-color: #0056b3;
}

th::after {
  content: '';
  position: absolute;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid white;
  transform: translateY(-50%);
  top: 50%;
  opacity: 0;
  transition: opacity 0.3s ease;
}

th.asc::after {
  opacity: 1;
}

th.desc::after {
  border-bottom: none;
  border-top: 5px solid white;
  opacity: 1;
}

td {
  background-color: #f9f9f9;
}

tr:nth-child(even) td {
  background-color: #f1f1f1;
}

tr:hover td {
  background-color: #e9ecef;
}

/* File Input */
#fileInput {
  display: none;
}

label[for="fileInput"] {
  cursor: pointer;
  font-size: 1.1em;
}

#sqlinput{
  height: 30px;
  width: 500px;
  margin: 5px;
}

.drag-drop-area {
  margin-bottom: 10px;
  border: 1px dashed black;
}

.sql-query textarea{
  width: 700px;
  height: 70px;
  margin-bottom: 5px;
}

.del-btn {
  background-color: red;
  color: white;
  border: 1px solid darkred;
  /* padding: 4px 8px;  */
  /* font-size: 16px; */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition on hover */
}

.del-btn:hover {
  background-color: darkred;
  transform: scale(1.05); /* Slightly increase the size on hover */
}

.del-btn:active {
  background-color: crimson; /* Change color on click */
  transform: scale(1); /* Reset scale on click */
}

td input {
  border: none;
  outline: none; /* Removes the outline on focus */
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
}

td input:focus {
  background-color: #e0e0e0; /* Changes background on focus */
}


.strategyChart{
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.Enable{
  color: rgb(82, 247, 82);  
}